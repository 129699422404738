interface SessionInit {
    status: string,
    customerEmail: string,
    paymentStatus: string,
    employees: number,
    welcomeMailSend: boolean
}

export class SessionModel {
    status: string;
    customerEmail: string;
    paymentStatus: string;
    employees: number;
    welcomeMailSend: boolean;

    constructor(init: SessionInit) {
        this.status = init.status;
        this.customerEmail = init.customerEmail;
        this.paymentStatus = init.paymentStatus;
        this.employees = init.employees;
        this.welcomeMailSend = init.welcomeMailSend;
    }
}