import {SuccessNoContent, useFetch} from "./useFetch";
import {SessionModel} from "../models/registration/session";
import {RegisterUserModel, RegistrationWithVatModel} from "../models/user/user";
import {TermsAndConditions} from "../models/registration/termsAndConditions";
import {CheckVATModel, CheckVATResult} from "../models/registration/checkVAT";
import UsernameAvailable from "../models/registration/usernameAvailable";

class CheckoutModel {
    clientSecret: string

    constructor(clientSecret: string) {
        this.clientSecret = clientSecret
    }
}

export function useRegistrationApi() {
    const checkoutUrl = process.env.REACT_APP_API_CHECKOUT_URL as string;
    const checkoutReturnUrl = process.env.REACT_APP_API_CHECKOUT_RETURN_URL as string;
    const registerUrl = process.env.REACT_APP_API_REGISTER_URL as string;
    const checkVATUrl = process.env.REACT_APP_API_CHECK_VAT_URL as string;
    const termsUrl = process.env.REACT_APP_API_TERMS_URL as string;
    const usernameAvailableUrl = process.env.REACT_APP_API_USERNAME_AVAILABLE as string;
    const verifyUrl = process.env.REACT_APP_API_VERIFY_EMAIL_URL as string;
    const requester = useFetch()

    const getCheckout = (subscription: string, email: string, customer?: string) => {
        let params: string;

        if (customer) {
            params = `?subscription=${subscription}&email=${email}&customer=${customer}`
        } else {
            params = `?subscription=${subscription}&email=${email}`
        }

        return requester.get(
            CheckoutModel,
            checkoutUrl + params,
            undefined,
            undefined,
            false,
            false,
            true
        ) as Promise<CheckoutModel>;
    };

    const getSessionStatus = (sessionId: string) => {
        const param = `?sessionId=${sessionId}`;
        return requester.get(SessionModel, checkoutReturnUrl + param) as Promise<SessionModel>;
    };

    const getTermsAndConditions = () => {
        return requester.get(
            TermsAndConditions,
            termsUrl,
            undefined,
            undefined,
            true,
            false,
            true
        ) as Promise<TermsAndConditions>;
    };

    const getIsUsernameAvailable = (username: string) => {
        const param = `?username=${username}`;
        console.log(username)
        return requester.get(
            UsernameAvailable,
            usernameAvailableUrl + param
        ) as Promise<UsernameAvailable>;
    };

    const postCheckVAT = (checkVATModel: CheckVATModel, update = false) => {
        const param = `?update=${update}`;
        return requester.post(CheckVATResult, checkVATUrl + param, checkVATModel) as
            Promise<CheckVATResult>;
    }

    const postVerifyEmail = (email: string, option: "user" | "employee" = "user") => {
        const param = `?email=${email}&option=${option}`;
        return requester.post(
            SuccessNoContent,
            verifyUrl + param,
            undefined,
            undefined,
            false,
            false,
            true,
        ) as Promise<SuccessNoContent>;
    }

    const postRegister = (registerModel: RegisterUserModel | RegistrationWithVatModel) => {
        return requester.post(
            SuccessNoContent,
            registerUrl,
            registerModel,
            undefined,
            false,
            false,
            true,
        ) as Promise<SuccessNoContent>;
    }

    return {
        getCheckout,
        getSessionStatus,
        getTermsAndConditions,
        getIsUsernameAvailable,
        postCheckVAT,
        postVerifyEmail,
        postRegister
    }
}