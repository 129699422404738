import {useDebouncedEffect, useFormSetup, usePhoneNumberInput} from "../../utils/customHooks";
import requireTranslation from "../../lang/locales/config";
import {EmployeeModel} from "../../models/Employee";
import {useNavigate, useParams} from "react-router-dom";
import {
    isInputErrorsEmpty,
    validateName, validatePhoneNumber,
    validateUsername
} from "../../utils/inputValidation";
import React, {SyntheticEvent, useEffect, useState} from "react";
import {OverlaySpinLoader, SpinLoader} from "../common/SpinLoader";
import {
    PasswordWithConfirmPassword,
    PhoneInput,
    TextInputWithLabel,
    usePasswordWithConfirmPassword
} from "../common/FormCommons";
import {useEmployeeNewRegisterLinkApi, useRegisterEmployeeApi} from "../../API/useEmployeeApi";
import {PageWithContent} from "../common/ContentStandards";
import {useRegistrationApi} from "../../API/useRegistrationApi";


interface InputErrors {
    name: string;
    username: string;
    phone: string;
}

interface RouteParam {
    [index: string]: string;
    token: string;
}

export default function RegisterEmployeePage() {
    const t = requireTranslation();
    const navigate = useNavigate();
    const initialInputErrors = {
        "name": "",
        "username": "",
        "phone": ""
    };
    const [ loading, setLoading ] = useState(false);
    const [
        awaiting, setAwaiting,
        inputErrors, setInputErrors,
        errorMessage, setErrorMessage,
        employee, setEmployee,
    ] = useFormSetup<EmployeeModel | undefined, InputErrors>(undefined, initialInputErrors);
    const {
        password, confirmPassword,
        isPasswordConfirmed, passwordConfirmIcon,
        inputErrors: passwordErrors, handleChangePassword,
        validatePasswordInput
    } = usePasswordWithConfirmPassword();
    const [ registerError, setRegisterError ] = useState("");
    const [ isUsernameAvailable, setIsUsernameAvailable ] = useState(true);
    const params = useParams<RouteParam>();
    const token: string | undefined = params.token;
    const { getEmployee, registerEmployee } = useRegisterEmployeeApi();
    const { getIsUsernameAvailable } = useRegistrationApi();
    const { getNewLink } = useEmployeeNewRegisterLinkApi();
    const {
        handlePhoneNumberChange,
        inputFieldValue,
        setCountryCode
    } = usePhoneNumberInput(
        EmployeeModel,
        setEmployee as React.Dispatch<React.SetStateAction<EmployeeModel>>,
    )

    useEffect(() => {
        if (token) {
            setLoading(true);
            getEmployee(token)
                .then(setEmployee)
                .catch((e) => {
                    setErrorMessage(e.message);
                })
                .finally(() => setLoading(false))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    useDebouncedEffect(() => {
        if (employee?.username) {
            getIsUsernameAvailable(employee.username)
                .then((data) => {
                    if (employee.username === data.username) {
                        setIsUsernameAvailable(data.available);
                    }
                })
                .catch((e) => {
                    console.log(e)
                })
        }
    }, 400, [employee?.username])

    useEffect(() => {
        if (!isUsernameAvailable) {
            const err = inputErrors;

            err.username = t.errors.usernameUnavailable;
            setInputErrors(prevState => ({ ...prevState, ...err }));
        } else {
            const err = inputErrors;

            err.username = "";

            setInputErrors(prevState => ({ ...prevState, ...err }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUsernameAvailable]);

    useEffect(() => {
        if (employee) {
            const change = {password: password};
            setEmployee((prevState) => {
                return new EmployeeModel({...prevState!!, ...change});
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password]);

    const handleSendNewRegisterLink = () => {
        if (token) {
            setAwaiting(true);
            getNewLink(token)
                .then(() => setErrorMessage(t.informationTexts.newLinkSendToEmail))
                .catch((e) => {
                    setErrorMessage(e.message);
                })
                .finally(() => setAwaiting(false))
        }
    };

    const handleChange = (event: any) => {
        const { name, value } = event.target;

        const change = {
            [name]: value,
        };

        setEmployee((employee) => {
            return new EmployeeModel({ ...employee!!, ...change });
        });
    };

    const handleRegister = () => {
        const validation1 = validateInput();
        const validation2 = validatePasswordInput();
        if (validation1 && validation2) {
            setAwaiting(true);
            registerEmployee(employee!!, token!!)
                .then(() => {
                    navigate("/login");
                })
                .catch((e) => {
                    if (e.message === t.responseMessage.errorUnique) {
                        const err = initialInputErrors;
                        err.username = e.message;
                        setInputErrors(err);
                    } else {
                        setRegisterError(e.message)
                    }
                })
                .finally(() => setAwaiting(false));
        }
    };

    const validateInput = (): boolean => {
        const err = initialInputErrors;

        err.name = validateName(employee!!.name);

        if (isUsernameAvailable || !employee!!.username) {
            err.username = validateUsername(employee!!.username);
        } else {
            err.username = t.errors.usernameUnavailable;
        }

        err.phone = validatePhoneNumber(employee!!.phone);

        setInputErrors(err);
        return isInputErrorsEmpty(err);
    };

    return (
        <PageWithContent>
                <SpinLoader
                    containerClassName="content color-main-background"
                    loading={loading}
                    loadMessage={t.loadingMessages.registerEmployee} />

                <OverlaySpinLoader
                    loading={awaiting}
                    loadMessage={t.awaiting} />

                <>
                    {!errorMessage && !loading && employee &&
                        <div className="content fold-out color-main-background">
                            <h2 className="content-headline">
                                {t.register}
                            </h2>
                            <div className="content-inner-form">
                                <form
                                    aria-label="employeeRegisterForm"
                                    name="employeeRegisterForm"
                                    onSubmit={(e: SyntheticEvent) => e.preventDefault()}>
                                    <TextInputWithLabel
                                        labelText={t.name}
                                        ariaLabel="employeeName"
                                        name="name"
                                        initialValue={employee.name}
                                        placeholder={`${t.enter} ${t.name.toLowerCase()}`}
                                        onChange={handleChange}
                                        error={inputErrors.name}/>
                                    <TextInputWithLabel
                                        labelText={t.username}
                                        ariaLabel="employeeUsername"
                                        name="username"
                                        initialValue={employee.username}
                                        placeholder={`${t.enter} ${t.username.toLowerCase()}`}
                                        onChange={handleChange}
                                        error={inputErrors.username}/>
                                    <PasswordWithConfirmPassword
                                        password={password}
                                        confirmPassword={confirmPassword}
                                        isPasswordConfirmed={isPasswordConfirmed}
                                        passwordConfirmIcon={passwordConfirmIcon}
                                        inputErrors={passwordErrors}
                                        handleChangePassword={handleChangePassword} />
                                    <PhoneInput
                                        ariaLabel="RegisterUserFormPhone"
                                        initialValue={inputFieldValue}
                                        setCountryCode={setCountryCode}
                                        error={inputErrors.phone}
                                        onChange={handlePhoneNumberChange}
                                    />
                                    <div className="row reverse margin">
                                        <button
                                            className="filled"
                                            onClick={handleRegister}>{t.register}</button>
                                    </div>

                                    {registerError &&
                                        <div className="row margin">
                                            <p className="error message">{registerError}</p>
                                        </div>
                                    }
                                </form>
                            </div>
                        </div>
                    }
                    {errorMessage && !loading && !employee && (
                        <div className="content fold-out color-main-background">
                            <h3 className="content-headline center-page">{errorMessage}</h3>
                            {errorMessage === t.responseMessage.errorTokenExp && (
                                <div>
                                    <p className="support-linebreak center-page">
                                        {t.informationTexts.registerLinkExpired}
                                    </p>
                                    <div className="row reverse margin">
                                        <button
                                            className="filled center-page"
                                            onClick={handleSendNewRegisterLink}>
                                            {t.sendNew}
                                        </button>
                                    </div>
                                </div>
                            )}
                            {errorMessage === t.responseMessage.errorIsRegistered && (
                                <div>
                                    <p className="support-linebreak center-page">
                                        {t.informationTexts.alreadyRegistered}
                                    </p>
                                </div>
                            )}
                        </div>
                    )}
                </>
        </PageWithContent>
    );
}