import React, {ChangeEvent, FormEvent, useEffect, useRef, useState} from "react";
import requireTranslation from "../../lang/locales/config";
import {isInputErrorsEmpty, validatePassword} from "../../utils/inputValidation";
import {CountryCode, isSupportedCountry} from "libphonenumber-js";
import {getCountryData, getCountryDataList, TCountryCode} from "countries-list";

interface DoubleButtonProps {
    outlinedButtonText: string;
    filledButtonText: string;
    onOutlinedButton: () => void;
    onFilledButton: () => void;
}

/**
 * Commonly used double button line/row, can be used in forms, cards, etc.
 * Consists of two buttons placed to the right of the container.
 * The filled button is the high emphasis, and outlined is the secondary choice.
 *
 * @param outlinedButtonText Text displayed in the outline button.
 * @param filledButtonText Text displayed in the filled button.
 * @param onOutlinedButton The function that should be called on outline button click.
 * @param onFilledButton The function that should be called on filled button click.
 * @constructor
 */
function DoubleButton(
    {
        outlinedButtonText,
        filledButtonText,
        onOutlinedButton,
        onFilledButton
    }: DoubleButtonProps
) {
    return (
        <div className="row margin reverse">
            <button className="filled" onClick={onFilledButton}>{filledButtonText}</button>
            <button className="outline" onClick={onOutlinedButton}>{outlinedButtonText}</button>
        </div>
    );
}

interface InputSuggestionProps {
    suggestion: string;
    suggestionBoldLength: number;
    onClick: (suggestion: string) => void;
}

const INPUT_TAB_INDEX = 99;

function InputSuggestion({ suggestion, suggestionBoldLength, onClick }: InputSuggestionProps) {
    return (
        <div
            tabIndex={INPUT_TAB_INDEX}
            onClick={() => onClick(suggestion)}>
            <strong>{suggestion.substring(0, suggestionBoldLength)}</strong>
            {suggestion.substring(suggestionBoldLength)}
        </div>
    )
}

interface InputWithLabelProps {
    children?: JSX.Element | JSX.Element[] | false;
    labelText: string;
    name?: string;
    ariaLabel?: string;
    autocompleteList?: string[];
    initialValue?: string | number | null;
    placeholder?: string;
    error?: string;
    rowOfTwo?: boolean;
    isReadonly?: boolean;
    isPassword?: boolean;
    onChange?: (event: any, alt_value?: boolean) => void;
    rightIcon?: string;
    rightIconColorControl?: boolean;
    rightIconClick?: () => void;
}

/**
 * Form input with a descriptive label.
 *
 * @param children Optional. This is intended for custom suggestion component(s).
 * @param labelText Required. Text for the label.
 * @param name Optional. Set to fill the input 'name' attribute.
 * @param ariaLabel Optional. Set if an aria-label text should be set.
 * @param autocompleteList Optional. Add a list of autocomplete suggestions.
 * @param initialValue Optional. Set if the input has an initial value.
 * @param placeholder Optional. Set if a placeholder is needed on the input.
 * @param error Optional. Set with a useState value if an error text should be displayed.
 * @param rowOfTwo Optional. Defaults to false, set to true if component is one out of two in a row.
 * @param isReadonly Defaults to false. Set true if the input field should be readonly.
 * @param isPassword Defaults to false. If password set true.
 * @param onChange Defaults to undefined. The change event handler function. This should not be included
 *                 if isReadonly = true
 * @param rightIcon Defaults to undefined. Set if an icon on the right side is wanted. String to location.
 *
 * @param rightIconColorControl Defaults to undefined. If a boolean is given. the icon will be red on false and green
 *                              on true. If no rightIcon is defined, setting this param will have no effect.
 * @param rightIconClick If defined, this function is called when icon is clicked.
 */
function TextInputWithLabel(
    {
        children,
        labelText,
        name = "",
        ariaLabel = "",
        autocompleteList = undefined,
        initialValue = "",
        placeholder = "",
        error = "",
        rowOfTwo = false,
        isReadonly = false,
        isPassword = false,
        onChange,
        rightIcon = undefined,
        rightIconColorControl = undefined,
        rightIconClick = undefined}: InputWithLabelProps
) {
    const [ suggestions ] = useState(autocompleteList ? autocompleteList.sort() : autocompleteList);
    const [ activeSuggestions, setActiveSuggestions ] = useState<InputSuggestionProps[]>([]);
    const errorRef = useRef<HTMLParagraphElement | null>(null);

    useEffect(() => {
        if (error) {
            errorRef.current?.scrollIntoView({behavior: 'smooth', block: 'center'})
        }
    }, [error]);


    const handleAutocomplete = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        if (suggestions && value !== '') {
            let suggestionProps: InputSuggestionProps[] = [];
            // eslint-disable-next-line array-callback-return
            suggestions.map((suggestion) => {
                if (suggestion.substring(0, value.length).toUpperCase() === value.toUpperCase()) {
                    let suggestionProp: InputSuggestionProps = {
                        suggestion: suggestion,
                        suggestionBoldLength: value.length,
                        onClick: handleSuggestionClick,
                    }
                    suggestionProps.push(suggestionProp);
                }
            })
            setActiveSuggestions(suggestionProps);
        }

        if (onChange) {
            onChange(e);
        }
    }

    const handleSuggestionClick = (suggestion: string) => {
        if (onChange) {
            const event = {
                target: {
                    value: suggestion,
                    name: name
                }
            }
            setActiveSuggestions([]);
            onChange(event);
        }
    };

    // FocusEvent<HTMLInputElement, HTMLElement> (won't accept it)
    const handleFocusLost = (e: any) => {
        if (e.relatedTarget === null || e.relatedTarget.tabIndex !== INPUT_TAB_INDEX) {
            setActiveSuggestions([]);
        }
    };

    return (
        <div className="column">
            <div className="row toColumn">
                <label
                    className={rowOfTwo ? "input-label row-of-two" : "input-label"}
                    htmlFor={name}>
                    {labelText}
                </label>
                <div className="autocomplete">
                    <div className="input-container">
                        <input
                            className={rowOfTwo ?
                                "input row-of-two" :
                                "input fit-container"}
                            aria-label={ariaLabel}
                            autoComplete={autocompleteList || children ? "off" : "on"}
                            name={name}
                            type={isPassword ? "password" : "text"}
                            placeholder={placeholder}
                            onBlur={autocompleteList ? (e) => handleFocusLost(e) : undefined}
                            readOnly={isReadonly}
                            onChange={autocompleteList ? handleAutocomplete : onChange}
                            value={initialValue ? initialValue : ""}>
                        </input>
                        {rightIcon &&
                            <img
                                className={rightIconColorControl !== undefined ?
                                    rightIconColorControl ?
                                        "input-icon in-context color-high-emphasis filter" :
                                        "input-icon in-context color-warn filter" :
                                    "input-icon in-context"}
                                src={rightIcon}
                                onClick={rightIconClick}
                                alt={"icon"}/>
                        }
                            {activeSuggestions && (
                                <div className="autocomplete-items">
                                    {activeSuggestions.map((value, index) => (
                                        <InputSuggestion
                                            suggestion={value.suggestion}
                                            suggestionBoldLength={value.suggestionBoldLength}
                                            key={index}
                                            onClick={value.onClick} />
                                    ))}
                                </div>
                            )}
                    </div>
                    {children}
                </div>
            </div>
            <p ref={errorRef} className="input-error fixed" role="alert">{error}</p>
        </div>
    );
}

interface AreaWithLabelProps {
    labelText: string;
    name?: string;
    ariaLabel?: string;
    initialValue?: string | number | null;
    placeholder?: string;
    error?: string;
    isReadonly?: boolean;
    onChange?: (event: any) => void;
}

/**
 * Form textarea with a descriptive label.
 *
 * @param labelText Required. Text for the label.
 * @param name Optional. Set to fill the input 'name' attribute.
 * @param ariaLabel Optional. Set if an aria-label text should be set.
 * @param initialValue Optional. Set if the input has an initial value.
 * @param placeholder Optional. Set if a placeholder is needed on the input.
 * @param error Optional. Set with a useState value if an error text should be displayed.
 * @param isReadonly Defaults to false. Set true if the input field should be readonly.
 * @param onChange Defaults to undefined. The change event handler function. This should not be included
 *                 if isReadonly = true
 */
function TextAreaWithLabel(
    {
        labelText,
        name = "",
        ariaLabel = "",
        initialValue = "",
        placeholder = "",
        error = "",
        isReadonly = false,
        onChange }: AreaWithLabelProps
) {

    return (
        <div className="column">
            <div className="row vertical-expandable toColumn">
                <label
                    className="input-label"
                    htmlFor={name}>
                    {labelText}
                </label>
                <div className="autocomplete">
                    <div className="input-container">
                        <textarea
                            className="input fit-container text-area"
                            aria-label={ariaLabel}
                            name={name}
                            placeholder={placeholder}
                            readOnly={isReadonly}
                            onChange={onChange}
                            value={initialValue ? initialValue : ""} />
                    </div>
                </div>
            </div>
            <p className="input-error fixed" role="alert">{error}</p>
        </div>
    );
}


interface PhoneInputProps {
    ariaLabel?: string;
    initialValue: string;
    countryCode?: CountryCode;
    error?: string;
    name?: string;
    asCombo?: boolean;
    setCountryCode: React.Dispatch<React.SetStateAction<CountryCode | undefined>>
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onSaveClick?: () => void;
}

/**
 *
 * @param ariaLabel Optional. Set if an aria-label text should be set. Set on input field.
 * @param initialValue inputFieldValue from usePhoneNumberInput.
 * @param countryCode Only set if a phone number exists. (Edit mode).
 * @param error Optional. Set with a useState value if an error text should be displayed.
 * @param name Defaults to 'phone'. Set if the name needs to be alternated, fx. if the model entry is something else.
 * @param asCombo Set true if PhoneInput should act like DetailsAndTextInputCombo.
 * @param setCountryCode Dispatcher from usePhoneNumberInput.
 * @param onChange handlePhoneNumberChange from usePhoneNumberInput.
 * @param onSaveClick If set a save button will be shown and a click will execute the set action. This will
 *                    only work if set asCombo
 * @constructor
 */
function PhoneInput (
    {
        ariaLabel = "",
        initialValue = "",
        countryCode = undefined,
        error = "",
        name = "phone",
        asCombo = false,
        setCountryCode,
        onChange,
        onSaveClick = undefined
    }: PhoneInputProps
) {
    const t = requireTranslation();
    const [ isEdit, setIsEdit ] = useState(false);
    const [ originalValue ] = useState(initialValue);
    const [ isChanged, setIsChanged ] = useState(false);
    const initialCountryData = countryCode ? getCountryData(countryCode as TCountryCode) : undefined;

    const onChangeSelection = (event: ChangeEvent<HTMLSelectElement>) => {
        setCountryCode(event.target.value as CountryCode);
    };

    const handleEditClick = () => {
        setIsEdit((prevState) => !prevState);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsChanged(event.target.value !== originalValue);
        onChange(event);
    };

    return (
        <>
            {asCombo && !isEdit ?
                <div
                    className="input-container box-radius clickable"
                    onClick={handleEditClick}>
                    <div className="row">
                        <div className="row toColumn">
                            <p className="title-line fixed-width">{t.phone}</p>
                            <p className="single-line">{initialValue}</p>
                        </div>
                        <img
                            className={error ?
                                "icon in-context-smaller no-margin color-warn text-filter" :
                                "icon in-context-smaller no-margin"}
                            src={error ? "error.svg" : "edit.svg"}
                            alt="icon"/>

                    </div>
                </div> :
                <div className="column">
                    <div className="row">
                        <div className="input-container">
                            {asCombo &&
                                <img
                                    className="icon-button clickable"
                                    src={"left_chevron.svg"}
                                    onClick={handleEditClick}
                                    alt={"icon"}/>
                            }
                            <div className="row toColumn">
                                <label
                                    className={asCombo ?
                                        "input-label no-margin" :
                                        "input-label"}
                                    htmlFor={name}>
                                    {t.phone}
                                </label>
                                <div className="autocomplete">
                                    <div className="input-container">
                                        <select
                                            className={asCombo ?
                                                "input select no-margin fit-context" :
                                                "input select fit-context"}
                                            onChange={onChangeSelection}>
                                            <>
                                                {!initialCountryData ?
                                                    <option value={undefined}></option> :
                                                    <option
                                                        key={initialCountryData.native + initialCountryData.iso2}
                                                        value={initialCountryData.iso2}>
                                                        {`+${initialCountryData.phone[0]} (${initialCountryData.iso2})`}
                                                    </option>

                                                }

                                                {getCountryDataList().sort((value1, value2) => {
                                                    return value1.phone[0] - value2.phone[0]
                                                    // eslint-disable-next-line array-callback-return
                                                }).map((country) => {
                                                    if (isSupportedCountry(country.iso2))
                                                        return (
                                                            <option key={country.native} value={country.iso2}>
                                                                {`+${country.phone[0]} (${country.iso2})`}
                                                            </option>
                                                        )
                                                })}
                                            </>
                                        </select>
                                        <input
                                            name={name}
                                            aria-label={ariaLabel}
                                            className={asCombo ?
                                                "input no-margin fit-content available" :
                                                "input fit-content available"}
                                            value={initialValue}
                                            onFocus={(e) => e.target.select()}
                                            onChange={handleChange}
                                            placeholder={`${t.enter.toLowerCase()} ${t.phone.toLowerCase()}`}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {onSaveClick && isChanged && asCombo &&
                        <div className="row reverse fold-out">
                            <p className="text-button clickable" onClick={onSaveClick}>
                                {t.save}
                            </p>
                        </div>
                    }
                    <p className="input-error fixed" role="alert">{error}</p>
                </div>
            }
        </>
    )
}


interface SelectFieldWithLabelProps {
    labelText: string;
    selection: string[] | number[];
    name?: string;
    ariaLabel?: string;
    initialValue?: string;
    error?: string;
    onChange?: (event: any) => void;
}

/**
 * Form select with a descriptive label.
 *
 * @param labelText Required. Text for the label.
 * @param selection The options to choose from.
 * @param name Optional. Set to fill the input 'name' attribute.
 * @param ariaLabel Optional. Set if an aria-label text should be set.
 * @param initialValue Optional. Set if a selection is already in place.
 * @param error Optional. Set with a useState value if an error text should be displayed.
 * @param onChange Defaults to undefined. The change event handler function. This should not be included
 *                 if isReadonly = true
 */
function SelectFieldWithLabel(
    {
        labelText,
        selection,
        name = "",
        ariaLabel = "",
        initialValue = "",
        error = "",
        onChange }: SelectFieldWithLabelProps
) {
    // Controls the blank selection if no initial value. Remove the blank when a selection has been made.
    const [blankValue, setBlankValue] = useState(!initialValue);

    const handleChange = (event: any) => {
        const { value } = event.target;

        if (blankValue && value) setBlankValue(false);
        if (onChange) onChange(event);
    }

    return (
        <div className="column">
            <div className="row toColumn">
                <label
                    className="input-label"
                    htmlFor={name}>
                    {labelText}
                </label>
                <div className="autocomplete">
                    <div className="input-container">
                        <select
                            className="input fit-container select"
                            aria-label={ariaLabel}
                            name={name}
                            defaultValue={initialValue}
                            onChange={initialValue ? onChange : handleChange}>
                            {selection.map((option) => (
                                <option
                                    key={option}
                                    value={option}>{option}</option>
                            ))}
                            {blankValue && <option value=""></option>}
                        </select>
                    </div>
                </div>
            </div>
            <p className="input-error fixed" role="alert">{error}</p>
        </div>
    );
}

interface NumberInputWithLabelProps extends InputWithLabelProps {
    maxLength?: number;
    strict?: boolean;
}

/**
 * Form input with a descriptive label.
 *
 * @param labelText Required. Text for the label.
 * @param name Optional. Set to fill the input 'name' attribute.
 * @param ariaLabel Optional. Set if an aria-label text should be set.
 * @param initialValue Optional. Set if the input has an initial value.
 * @param placeholder Optional. Set if a placeholder is needed on the input.
 * @param error Optional. Set with a useState value if an error text should be displayed.
 * @param isReadonly Defaults to false. Set true if the input field should be readonly.
 * @param rowOfTwo Optional. Defaults to false, set to true if component is one out of two in a row.
 * @param maxLength Optional. Sets a limit to length of number (12 has length of 2). Default: no limit.
 * @param strict Defaults to true. If false the normal number verification before calling onChange won't be active.
 *               onChange will be called directly.
 * @param onChange Defaults to undefined. The change event handler function. This should not be included
 *                 if isReadonly = true, but should be included otherwise.
 * @constructor
 */
function NumberInputWithLabel(
    {
        labelText,
        name = "",
        ariaLabel = "",
        initialValue = "",
        placeholder = "",
        error = "",
        rowOfTwo = false,
        isReadonly = false,
        maxLength = undefined,
        strict = true,
        onChange }: NumberInputWithLabelProps
) {
    const errorRef = useRef<HTMLParagraphElement | null>(null);

    useEffect(() => {
        if (error) {
            errorRef.current?.scrollIntoView({behavior: 'smooth', block: 'center'})
        }
    }, [error]);

    const handleChangeNumber = (event: any) => {
        const { value } = event.target;
        const format = /[^0-9]/g;
        // console.log(value)

        if (maxLength) {
            if (value.length > maxLength) return;
        }

        if (format.test(value)) return;

        if (value.length === 0) {
            onChange!!(event, true);
        } else onChange!!(event);
        // onChange!!(event);
    };

    return (
        <div className="column">
            <div className="row toColumn">
                <label
                    className={rowOfTwo ? "input-label row-of-two" : "input-label"}
                    htmlFor={name}>
                    {labelText}
                </label>
                <div className="autocomplete">
                    <div className="input-container">
                        <input
                            className={rowOfTwo ?
                                "input row-of-two" :
                                "input fit-container"}
                            aria-label={ariaLabel}
                            name={name}
                            placeholder={placeholder}
                            readOnly={isReadonly}
                            onChange={onChange ? (strict ? handleChangeNumber : onChange) : onChange}
                            value={initialValue || initialValue === 0 ? initialValue : ""} />
                    </div>
                </div>
            </div>

            <p ref={errorRef} className="input-error fixed" role="alert">{error}</p>

        </div>
    );
}

interface DatePickerWithLabelProps {
    labelText: string;
    name?: string;
    ariaLabel?: string;
    initialValue?: string | number;
    minDate?: string;
    maxDate?: string;
    error?: string;
    onChange?: (event: any) => void;
}

/**
 * Form input with a descriptive label.
 *
 * @param labelText Required. Text for the label.
 * @param name Optional. Set to fill the input 'name' attribute.
 * @param ariaLabel Optional. Set if an aria-label text should be set.
 * @param initialValue Optional. Set if the input has an initial value.
 * @param minDate Optional. Set if a minimum date are wanted.
 * @param maxDate Optional. Set if a Maximum date are wanted.
 * @param error Optional. Set if an error message is needed.
 * @param onChange Optional. Function/method to run when the date changes.
 */
function DatePickerWithLabel(
    {
        labelText,
        name = "",
        ariaLabel = "",
        initialValue = "",
        minDate = undefined,
        maxDate = undefined,
        error = "",
        onChange }: DatePickerWithLabelProps
) {
    return (
        <div className="column">
            <div className="row toColumn">
                <label
                    className="input-label"
                    htmlFor={name}>
                    {labelText}
                </label>
                <div className="autocomplete">
                    <div className="input-container">
                        <input
                            className="input fit-container"
                            name={name}
                            aria-label={ariaLabel}
                            type="date"
                            value={initialValue}
                            min={minDate}
                            max={maxDate}
                            onChange={onChange} />
                    </div>
                </div>
            </div>

            <p className="input-error fixed" role="alert">{error}</p>
        </div>
    )
}


interface DetailsFormComboProps {
    labelText: string;
    name?: string;
    ariaLabel?: string;
    autocompleteList?: string[];
    initialValue?: string | number | null;
    placeholder?: string;
    error?: string;
    rowOfTwo?: boolean;
    isReadonly?: boolean;
    isPassword?: boolean;
    onChange?: (event: any, alt_value?: boolean) => void;
    onSaveClick?: () => void;
}


/**
 * Starts as a details information row, if right edit icon is clicked, it switches to
 * a form input with a descriptive label.
 *
 * @param labelText Required. Text for the label.
 * @param name Optional. Set to fill the input 'name' attribute.
 * @param ariaLabel Optional. Set if an aria-label text should be set.
 * @param initialValue Optional. Set if the input has an initial value.
 * @param placeholder Optional. Set if a placeholder is needed on the input.
 * @param error Optional. Set with a useState value if an error text should be displayed.
 * @param rowOfTwo Optional. Defaults to false, set to true if component is one out of two in a row.
 * @param isReadonly Defaults to false. Set true if the input field should be readonly.
 * @param isPassword Defaults to false. If password set true.
 * @param onChange Defaults to undefined. The change event handler function. This should not be included
 *                 if isReadonly = true
 * @param onSaveClick Defaults to undefined. If set, a save button will be shown and a click will execute the set action
 */
function DetailsAndTextInputCombo(
    {
        labelText,
        name = "",
        ariaLabel = "",
        initialValue = "",
        placeholder = "",
        error = "",
        rowOfTwo = false,
        isReadonly = false,
        isPassword = false,
        onChange,
        onSaveClick,
    }: DetailsFormComboProps
) {
    const t = requireTranslation();
    const [ isEdit, setIsEdit ] = useState(false);
    const [ originalValue ] = useState(initialValue);
    const [ isChanged, setIsChanged ] = useState(false);

    const handleEditClick = () => {
        setIsEdit((prevState) => !prevState);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsChanged(event.target.value !== originalValue);
        if (onChange) {
            onChange(event);
        }
    };

    return (
        <>
            {!isEdit ?
                <div
                    className="input-container box-radius clickable"
                    onClick={handleEditClick}>
                    <div className="row">
                        <div className="row toColumn">
                            <p className="title-line fixed-width">{labelText}</p>
                            <p className="single-line">{initialValue}</p>
                        </div>
                        <img
                            className={error ?
                                "icon in-context-smaller no-margin color-warn text-filter" :
                                "icon in-context-smaller no-margin"}
                            src={error ? "error.svg" : "edit.svg"}
                            alt="icon" />

                    </div>
                </div> :
                <div className="column">
                    <div className="row">
                        <div
                            className="input-container">
                            <img
                                className="icon-button clickable"
                                src={"left_chevron.svg"}
                                onClick={handleEditClick}
                                alt={"icon"}/>
                            <div className="row toColumn">
                                <label
                                    className={rowOfTwo ?
                                        "input-label no-margin no-padding row-of-two" :
                                        "input-label no-margin no-padding"}
                                    htmlFor={name}>
                                    {labelText}
                                </label>
                                <input
                                    className={rowOfTwo ?
                                        "input no-margin fit-container row-of-two" :
                                        "input no-margin fit-container"}
                                    aria-label={ariaLabel}
                                    name={name}
                                    type={isPassword ? "password" : "text"}
                                    placeholder={placeholder}
                                    readOnly={isReadonly}
                                    onChange={handleChange}
                                    value={initialValue ? initialValue : ""}>
                                </input>
                            </div>
                        </div>
                    </div>
                    {onSaveClick && isChanged &&
                        <div className="row reverse fold-out">
                            <p className="text-button clickable" onClick={onSaveClick}>
                                {t.save}
                            </p>
                        </div>
                    }
                    {error &&
                        <p className="input-error fixed" role="alert">{error}</p>
                    }
                </div>
            }
        </>
    );
}


interface PasswordConfirmInputErrors {
    password: string;
    confirmPassword: string;
}

function usePasswordWithConfirmPassword() {
    const t = requireTranslation();
    const initialInputErrors: PasswordConfirmInputErrors = {
        password: "",
        confirmPassword: "",
    }
    const [ inputErrors, setInputErrors ] = useState(initialInputErrors);
    const [ password, setPassword ] = useState("");
    const [ confirmPassword, setConfirmPassword ] = useState("");
    const [ isPasswordConfirmed, setIsPasswordConfirmed ] = useState(false);
    const [ passwordConfirmIcon, setPasswordConfirmIcon ] = useState<string>();

    const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        if (name === "password") {
            setPassword(value);
            setIsPasswordConfirmed(value === confirmPassword);
            setPasswordConfirmIcon(value === confirmPassword ? "done.svg" : "error.svg");
        } else {
            setConfirmPassword(value);
            setIsPasswordConfirmed(password === value);
            setPasswordConfirmIcon(password === value ? "done.svg" : "error.svg");
        }
    }

    const validatePasswordInput = (): boolean => {
        const err = initialInputErrors;

        err.password = validatePassword(password);
        err.confirmPassword = isPasswordConfirmed ? "" : t.errors.passwordConfirm;

        setInputErrors(err);
        return isInputErrorsEmpty(err);
    };

    return {
        password,
        confirmPassword,
        isPasswordConfirmed,
        passwordConfirmIcon,
        inputErrors,
        handleChangePassword,
        validatePasswordInput
    }
}

interface PasswordWithConfirmPasswordProps {
    password: string;
    confirmPassword: string;
    isPasswordConfirmed: boolean;
    passwordConfirmIcon: string | undefined;
    inputErrors: PasswordConfirmInputErrors;
    handleChangePassword: (event: ChangeEvent<HTMLInputElement>) => void;
}


/**
 * A Password input field with a coherent confirm-password field.
 * Use with usePasswordConfirmPassword.
 * Use within a ContentForm context.
 *
 * @param password The value of the password field.
 * @param confirmPassword The value of the repetition field.
 * @param isPasswordConfirmed Boolean that tells whether the two fields are equal.
 * @param passwordConfirmIcon The icon that will indicate to the user whether the repeat is correct.
 * @param inputErrors The error interface indicating if an error should be displayed in the text field.
 * @param handleChangePassword The function to run on change event.
 */
function PasswordWithConfirmPassword (
    {
        password,
        confirmPassword,
        isPasswordConfirmed,
        passwordConfirmIcon,
        inputErrors,
        handleChangePassword
    }: PasswordWithConfirmPasswordProps) {
    const t = requireTranslation();

    return (
        <>
            <TextInputWithLabel
                labelText={t.password}
                ariaLabel="ResetPasswordFormPassword"
                name="password"
                placeholder={`${t.enter} ${t.password.toLowerCase()}`}
                initialValue={password}
                onChange={handleChangePassword}
                isPassword={true}
                error={inputErrors.password}
            />
            <TextInputWithLabel
                labelText={`${t.confirm} ${t.password.toLowerCase()}`}
                ariaLabel="ResetPasswordFormConfirmPassword"
                name="confirmPassword"
                placeholder={`${t.reenter} ${t.password.toLowerCase()}`}
                initialValue={confirmPassword}
                onChange={handleChangePassword}
                isPassword={true}
                rightIcon={passwordConfirmIcon ?
                    `${window.location.origin}/${passwordConfirmIcon}` :
                    passwordConfirmIcon}
                rightIconColorControl={isPasswordConfirmed}
                error={inputErrors.confirmPassword}
            />
        </>
    )
}

interface FileInputWithLabelProps {
    labelText: string;
    buttonText: string;
    uploadFileText: string;
    accepted?: string;
    name?: string;
    ariaLabel?: string;
    initialValue?: string | number | null;
    placeholder?: string;
    error?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onRemove?: () => void;
}

/**
 * Form textarea with a descriptive label.
 *
 * @param labelText Required. Text for the label.
 * @param buttonText Required. Text for the button.
 * @param uploadFileText Required. Text to be in the field.
 * @param accepted Optional. Set to specify the files to support, fx. '.jpg, .jpeg, .png'.
 * @param name Optional. Set to fill the input 'name' attribute.
 * @param ariaLabel Optional. Set if an aria-label text should be set.
 * @param error Optional. Set with a useState value if an error text should be displayed.
 * @param onChange Defaults to undefined. The change event handler function.
 * @param onRemove Defaults to undefined. Set with a handler function, if a little clickable remove icon should
 *                 be shown to the right.
 */
function FileInputWithLabel(
    {
        labelText,
        buttonText,
        uploadFileText,
        accepted,
        name,
        ariaLabel,
        error,
        onChange,
        onRemove,
    }: FileInputWithLabelProps
) {
    return (
        <div className="column">
            <div className="row toColumn">
                <label
                    className="input-label">
                    {labelText}
                </label>
                <div className="input input-row">
                    <button
                        className="filled" onClick={() => {
                        document.getElementById(labelText)?.click()
                    }}>
                        {buttonText}
                    </button>
                    <p className="single-line">
                        {uploadFileText}
                    </p>
                    <input
                        id={labelText}
                        className="input file-input"
                        aria-label={ariaLabel}
                        type="file"
                        accept={accepted}
                        name={name}
                        onChange={onChange}/>
                    {onRemove && (
                        <img
                            className="icon-button smaller-no-margin clickable"
                            src={"close.svg"}
                            alt={"Close"}
                            onClick={onRemove}/>
                    )}
                </div>
            </div>
            <p className="input-error fixed" role="alert">{error}</p>
        </div>
    )
}

interface FormSubHeadlineProps {
    headline: string;
    subtitle?: string;
    centerHeadline?: boolean;
}

function FormSubHeadline({ headline, subtitle, centerHeadline = false }: FormSubHeadlineProps) {
    return (
        <div className="input-row">
            <h3 className={centerHeadline ?
                "content-headline center-page" :
                "content-headline"}>{headline}</h3>
            {subtitle && (
                <h4 className={centerHeadline ?
                    "support-linebreak center-page" :
                    "support-linebreak"}>{subtitle}</h4>
            )}
        </div>
    )
}


interface RangeSelectorProps {
    min: number;
    max: number;
    startValue?: number;
    onChange?: (value: number) => void;
}

function RangeSelector({ min, max, startValue, onChange }: RangeSelectorProps) {
    const [ value, setValue ] = useState(startValue ? startValue : 0);

    useEffect(() => {
        if (startValue) {
            handleRange(
                {"currentTarget": {
                    "value": startValue.toString(),
                    "max": max
                }} as unknown as FormEvent<HTMLInputElement>
            )
        }
    }, [startValue]);

    const handleRange = (e: FormEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value as unknown as number;
        const max = e.currentTarget.max as unknown as number;
        const progress = (value / max) * 100

        setValue(value);

        const element = document.getElementById("range-selector") as HTMLInputElement;
        element.style.background = `linear-gradient(to right, var(--color-light-blue) ${progress}%, lightgrey ${progress}%)`;
    }

    return (
        <div className="row space-between">
            <input
                id="range-selector"
                type="range"
                min={min}
                max={max}
                value={value}
                onInput={handleRange}
                onChange={onChange ? (e) => onChange(Number(e.target.value)) : undefined} />
            <h3>{value}</h3>
        </div>
    )
}


export {
    DoubleButton,
    TextInputWithLabel,
    TextAreaWithLabel,
    PhoneInput,
    SelectFieldWithLabel,
    NumberInputWithLabel,
    DatePickerWithLabel,
    DetailsAndTextInputCombo,
    usePasswordWithConfirmPassword,
    PasswordWithConfirmPassword,
    FileInputWithLabel,
    FormSubHeadline,
    RangeSelector
}