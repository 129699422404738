import {SuccessNoContent, useFetch} from "./useFetch";
import {
    SubscriptionCancelReactivateOutcome,
    SubscriptionChangeOutcome,
    SubscriptionStatus
} from "../models/user/subscription";
import {SubscriptionUpdateOption} from "../components/utils/Enums";

export function useSubscriptionApi() {
    const subscriptionUrl = process.env.REACT_APP_API_SUBSCRIPTION_URL as string;
    const requester = useFetch();

    const getSubscriptionStatus = () => {
        return requester.get(SubscriptionStatus, subscriptionUrl) as Promise<SubscriptionStatus>;
    }

    const getSubscriptionChangeOutcome = (subscription: number, employees: number) => {
        const params = `?subscription=${subscription}&employees=${employees}`;
        return requester.get(
            SubscriptionChangeOutcome,
            subscriptionUrl + params,
            undefined,
            undefined,
            false,
            false,
            true
        ) as Promise<SubscriptionChangeOutcome>;
    }

    const updateSubscription = (
        subscription: number | null = null,
        employees: number | null = null
    ) => {
        const param = `?updateOption=${SubscriptionUpdateOption.CHANGE}&subscription=${subscription}&employees=${employees}`;
        return requester.put(
            SuccessNoContent,
            subscriptionUrl + param,
            undefined,
            undefined,
            false,
            false,
            true
        ) as Promise<SuccessNoContent>;
    }

    const cancelSubscription = () => {
        const param = `?updateOption=${SubscriptionUpdateOption.CANCEL}`;
        return requester.put(
            SubscriptionCancelReactivateOutcome,
            subscriptionUrl + param,
            undefined,
            undefined,
            false,
            false,
            true
        ) as Promise<SubscriptionCancelReactivateOutcome>;
    }

    const reactivateSubscription = () => {
        const param = `?updateOption=${SubscriptionUpdateOption.REACTIVATE}`;
        return requester.put(
            SubscriptionCancelReactivateOutcome,
            subscriptionUrl + param,
            undefined,
            undefined,
            false,
            false,
            true
        ) as Promise<SubscriptionCancelReactivateOutcome>;
    }

    return {
        getSubscriptionStatus,
        getSubscriptionChangeOutcome,
        updateSubscription,
        cancelSubscription,
        reactivateSubscription
    }
}