export enum AirflowTypes {
    OUTGOING,
    INGOING
}

export enum AccessLevels {
    Admin,
    User,
    Employee,
}

export function isAdmin(accessLevel: number): boolean {
    return AccessLevels.Admin.valueOf() === accessLevel;
}

export function isUser(accessLevel: number): boolean {
    return AccessLevels.User.valueOf() === accessLevel;
}

export function isEmployee(accessLevel: number): boolean {
    return AccessLevels.Employee.valueOf() === accessLevel;
}

export enum SubscriptionEnum {
    Basic,
    Extended,
    Free,
    NotActive,
    NotCreatedBasic = -1,
    NotCreatedExtended = -2
}

export function isActive(subscription: number): boolean {
    return (subscription === SubscriptionEnum.Basic.valueOf() ||
        subscription === SubscriptionEnum.Extended.valueOf() ||
        subscription === SubscriptionEnum.Free.valueOf())
}

export function isNotCreated(subscription: number): boolean {
    return (subscription === SubscriptionEnum.NotCreatedBasic.valueOf() ||
        subscription === SubscriptionEnum.NotCreatedExtended.valueOf())
}

export function getSubscriptionToCreate(subscription: number): number {
    if (subscription === SubscriptionEnum.NotCreatedBasic) return SubscriptionEnum.Basic.valueOf()
    else if (subscription === SubscriptionEnum.NotCreatedExtended) return SubscriptionEnum.Extended.valueOf()
    else return SubscriptionEnum.Basic.valueOf()
}

export enum SubscriptionUpdateOption {
    CANCEL = "cancel",
    REACTIVATE = "re-activate",
    CHANGE = "change",
    UPGRADE = "upgrade",
    DOWNGRADE = "downgrade",
    NONE = "none"
}