import {UserState} from "../../state/userSlice";
import {SubscriptionEnum} from "../../components/utils/Enums";
import {CheckVATResult, VatInformation} from "../registration/checkVAT";

interface OrgUserModelInit {
    id: string;
    username: string;
    name: string;
    firmName: string;
    firmEmail: string;
    privateEmail: string;
    phone: string;
    subscription: number;
    employees: number
    created: number;
    lastLogin: number;
}

export class OrgUserModel implements OrgUserModelInit {
    id: string;
    username: string;
    name: string;
    firmName: string;
    firmEmail: string;
    privateEmail: string;
    phone: string;
    subscription: number;
    employees: number;
    created: number;
    lastLogin: number;

    constructor(init: OrgUserModelInit) {
        this.id = init.id;
        this.username = init.username;
        this.name = init.name;
        this.firmName = init.firmName;
        this.firmEmail = init.firmEmail;
        this.privateEmail = init.privateEmail;
        this.phone = init.phone;
        this.subscription = init.subscription;
        this.employees = init.employees;
        this.created = init.created;
        this.lastLogin = init.lastLogin;
    }
}

interface UserModelInit {
    username: string;
    name: string;
    firmName: string;
    firmEmail: string;
    privateEmail: string;
    phone: string;
    hasFirmHeader: boolean;
    hasBalancingFrontpage: boolean;
    maxEmployees: number;
    subscription: number;
    accessLevel: number;
}

export class UserModel implements UserModelInit {
    username: string;
    name: string;
    firmName: string;
    firmEmail: string;
    privateEmail: string;
    phone: string;
    hasFirmHeader: boolean;
    hasBalancingFrontpage: boolean;
    maxEmployees: number;
    subscription: number;
    accessLevel: number;

    constructor(init: UserModelInit | UserState) {
        this.username = init.username;
        this.name = init.name;
        this.firmName = init.firmName;
        this.firmEmail = init.firmEmail;
        this.privateEmail = init.privateEmail;
        this.phone = init.phone;
        this.hasFirmHeader = init.hasFirmHeader;
        this.hasBalancingFrontpage = init.hasBalancingFrontpage;
        this.maxEmployees = init.maxEmployees;
        this.subscription =  init.subscription;
        this.accessLevel = init.accessLevel;
    }
}


interface UserUpdateModelInit {
    name: string;
    firmName: string;
    firmEmail: string;
    privateEmail: string;
    phone: string;
}


export class UserUpdateModel implements UserUpdateModelInit {
    name: string;
    firmName: string;
    firmEmail: string;
    privateEmail: string;
    phone: string = "";

    constructor(init: UserState | UserUpdateModel) {
        this.name = init.name;
        this.firmName = init.firmName;
        this.firmEmail = init.firmEmail;
        this.privateEmail = init.privateEmail;
        this.phone = init.phone;
    }
}


interface RegisterUserModelInit {
    name: string;
    username: string;
    password: string;
    firmName: string;
    firmEmail: string;
    privateEmail: string;
    phone: string;
    subscription: number;
    employees: number;
}

export class RegisterUserModel implements RegisterUserModelInit {
    name: string = "";
    username: string = "";
    password: string = "";
    firmName: string = "";
    firmEmail: string = "";
    privateEmail: string = "";
    phone: string = "";
    subscription: number = SubscriptionEnum.Basic;
    employees: number = 0;

    constructor(init?: RegisterUserModelInit) {
        if (!init) return;

        this.name = init.name;
        this.username = init.username;
        this.password = init.password;
        this.firmName = init.firmName;
        this.firmEmail = init.firmEmail;
        this.privateEmail = init.privateEmail;
        this.phone = init.phone;
        this.subscription = init.subscription;
        this.employees = init.employees;
    }
}

export class ExtendedRegistrationModel extends RegisterUserModel {
    constructor(readonly passphrase: string, registrationInit: RegisterUserModelInit) {
        super(registrationInit);
    }
}

export class RegistrationWithVatModel extends RegisterUserModel {
    vatInformation: VatInformation | null = null;
    verificationCode: string;

    constructor(verificationCode: string, registrationInit: RegisterUserModelInit, checkVatResults?: CheckVATResult) {
        super(registrationInit);
        this.verificationCode = verificationCode;

        if (checkVatResults) {
            this.vatInformation = {
                vatNumber: checkVatResults.vatNumber,
                countryCode: checkVatResults.countryCode,
                name: checkVatResults.name!!,
                address: checkVatResults.address!!,
                zipCode: checkVatResults.zipCode,
                city: checkVatResults.city
            };
        }
    }
}

export class ResetPasswordModel {
    password: string = "";

    constructor(password?: string) {
        if (!password) return;

        this.password = password;
    }
}