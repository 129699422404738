interface UsernameAvailableInit {
    username: string;
    available: boolean;
}

export default class UsernameAvailable implements UsernameAvailableInit {
    available: boolean;
    username: string;

    constructor(init: UsernameAvailableInit) {
        this.available = init.available;
        this.username = init.username;
    }
}