import requireTranslation from "../../lang/locales/config";
import {EmployeeModel} from "../../models/Employee";
import React, {SyntheticEvent} from "react";
import {
    validateTextArea,
    validateName,
    isInputErrorsEmpty, validateEmail
} from "../../utils/inputValidation";
import {ConfirmationDialog, useDialog} from "../common/ConfirmationDialog";
import {useEmployeeApi} from "../../API/useEmployeeApi";
import {TextAreaWithLabel, TextInputWithLabel} from "../common/FormCommons";
import {OverlaySpinLoader} from "../common/SpinLoader";
import {useFormSetup} from "../../utils/customHooks";
import {InformationRow} from "../common/TextCommons";
import {Content} from "../common/ContentStandards";

interface EmployeeUserFormProps {
    initialEmployee?: EmployeeModel
    onDone: (statusMessage?: string) => void;
}

interface InputErrorInter {
    name: string;
    description: string;
    email: string;
    phone: string;
}

function EmployeeUserForm({ initialEmployee = undefined, onDone }: EmployeeUserFormProps) {
    const t = requireTranslation();
    const { postEmployee, putEmployee, deleteEmployee } = useEmployeeApi();
    const {isOpen, message: dialogMessage, setDialogMessage, toggle} = useDialog();
    const initialInputErrors: InputErrorInter = {
        name: "",
        description: "",
        email: "",
        phone: ""
    }
    const [
        awaiting, setAwaiting,
        inputErrors, setInputErrors,
        error, setError,
        employee, setEmployee,
    ] = useFormSetup(initialEmployee ? initialEmployee : new EmployeeModel(), initialInputErrors);

    /**
     * Handles a change in an input field.
     * Updates the EmployeeModel to match the change.
     */
    const handleChange = (event: any) => {
        const { name, value } = event.target;
        const change = {
            [name]: value,
        };

        setEmployee((employee) => {
            return new EmployeeModel({ ...employee, ...change });
        });
    };

    /**
     * Handles when the add button is clicked. Validates input and
     * sends a POST request to the server.
     */
    const handleAdd = async () => {
        if (validateInput()) {
            // console.log("New employee input accepted")
            setAwaiting(true);
            await postEmployee(employee)
                .then(() => {
                    onDone(t.messages.successAdd);
                })
                .catch((e) => {
                    if (e instanceof Error) setError(e.message)
                })
                .finally(() => setAwaiting(false));
        }
    };

    /**
     * Handles when the save button is clicked. Validates input and
     * sends a PUT request to the server.
     */
    const handleSave = async () => {
        if (validateInput()) {
            // console.log("Edit of employee input accepted")
            setAwaiting(true);
            await putEmployee(employee)
                .then(() => {
                    onDone(t.messages.successEdit);
                })
                .catch((e) => {
                    if (e instanceof Error) setError(e.message)
                })
                .finally(() => setAwaiting(false));
        }
    };

    /**
     * Toggles a confirmation dialog when delete button is clicked.
     */
    const handleDeleteEmployee = () => {
        setDialogMessage(t.messages.confirmDelete + employee.name + "?");
        toggle();
    };

    /**
     * When confirmation dialog delete button is clicked, a DELETE request is send to the
     * server, if successful, navigate back to overview.
     */
    const handleConfirmDelete = async () => {
        // console.log("confirm delete")
        toggle();
        setAwaiting(true);
        // console.log(initialEmployee)
        await deleteEmployee(initialEmployee!!)
            .then(() => {
                onDone(t.messages.successDelete)
            })
            .catch((e) => { if (e instanceof Error) setError(e.message); })
            .finally(() => setAwaiting(false));
    };

    /**
     * Validates the input fields. Returns true if all input is valid.
     */
    const validateInput = (): boolean => {
        let err = { ...inputErrors };

        err.name = validateName(employee.name);
        err.description = validateTextArea(employee.description);
        err.email = validateEmail(employee.email);

        setInputErrors(err);
        return isInputErrorsEmpty<InputErrorInter>(err);
    }

    return (
        <>
            <OverlaySpinLoader
                loading={awaiting}
                loadMessage={`${t.awaiting}...`} />

            {isOpen && (
                <ConfirmationDialog
                    toggle={toggle}
                    message={dialogMessage}
                    onConfirm={handleConfirmDelete}/>
            )}

            <Content
                headline={initialEmployee ?
                        `${t.edit} ${t.employee}` :
                        `${t.new} ${t.employee}`}
                infoIconText={t.informationTexts.employeeRegistrationInformation}>
                <div className="content-inner-form">
                    <form
                        aria-label="employee form"
                        name="employee form"
                        onSubmit={(e: SyntheticEvent) => e.preventDefault()}>
                        {!initialEmployee ?
                            <TextInputWithLabel
                                labelText={t.name}
                                ariaLabel="employee name"
                                name="name"
                                initialValue={employee.name}
                                placeholder={`${t.enter} ${t.name.toLowerCase()}`}
                                onChange={handleChange}
                                error={inputErrors.name}/> :
                            <InformationRow
                                title={t.name}
                                text={employee.name} />
                        }
                        <TextAreaWithLabel
                            labelText={t.description}
                            name="description"
                            ariaLabel="employee description"
                            placeholder={`${t.enter} ${t.description.toLowerCase()}`}
                            initialValue={employee.description}
                            error={inputErrors.description}
                            onChange={handleChange}/>
                        {!initialEmployee ?
                            <>
                                <TextInputWithLabel
                                    labelText={t.email}
                                    name="email"
                                    ariaLabel="employeeEmail"
                                    placeholder={`${t.enter} ${t.email.toLowerCase()}`}
                                    initialValue={employee.email}
                                    error={inputErrors.email}
                                    onChange={handleChange}/>
                            </> :
                            <>
                                <InformationRow
                                    title={t.email}
                                    text={employee.email} />
                                {employee.email !== employee.phone &&
                                    <InformationRow
                                        title={t.phone}
                                        text={employee.phone}/>
                                }
                            </>
                        }
                        <div className="row reverse margin">
                            <button
                                className="filled"
                                onClick={!initialEmployee ? handleAdd : handleSave}>
                                {!initialEmployee ? t.add : t.edit}
                            </button>
                            {initialEmployee && (
                                <button className="outline" onClick={handleDeleteEmployee}>{t.delete}</button>
                            )}
                        </div>

                        {error && (
                            <div className="row margin">
                                <p className="error message">{error}</p>
                            </div>
                        )}
                    </form>
                </div>
            </Content>
        </>
    );
}

export default EmployeeUserForm;