import React, {useEffect, useState} from "react";
import requireTranslation from "../../lang/locales/config";
import {SubscriptionEnum} from "./Enums";
import {RangeSelector} from "../common/FormCommons";

interface SubscriptionProps {
    isSelected: boolean,
    onClick?: () => void | undefined;
    onFinalPriceChange?: (price: number, employeeCount: number) => void;
    startEmployeeCount?: number;
    inactive?: boolean
}

export function BasicSubscription({ isSelected, onClick, onFinalPriceChange, startEmployeeCount, inactive = false }: SubscriptionProps) {
    const t = requireTranslation();
    const price = t.subscriptionBasic.campaignText ?
        t.subscriptionBasic.campaignPrice :
        t.subscriptionBasic.price;
    const pricePerEmployee = t.subscriptionBasic.campaignText ?
        t.subscriptionBasic.campaignPricePerEmployee:
        t.subscriptionBasic.pricePerEmployee;
    const [ employeeCount, setEmployeeCount ] = useState(startEmployeeCount ?
        startEmployeeCount :
        0
    );
    const [ finalPrice, setFinalPrice ] = useState(startEmployeeCount ?
        price + (pricePerEmployee * startEmployeeCount) :
        price
    );

    useEffect(() => {
        if (startEmployeeCount) {
            setFinalPrice(price + (pricePerEmployee * startEmployeeCount));
        }
    }, [startEmployeeCount]);

    const employeeCountChange = (value: number) => {
        const currentPrice = price + (pricePerEmployee * value)

        setEmployeeCount(value);
        setFinalPrice(currentPrice);

        if (onFinalPriceChange) {
            onFinalPriceChange(currentPrice, value);
        }
    };

    return (
        <div
            className={isSelected ? "card color-positive" : "card color-sec-background"}
            onClick={onClick}>
            <h2 className="card-header center-page">{t.subscriptionBasic.name}</h2>
            {t.subscriptionBasic.included.map((include, index) => (
                <div className="row" key={index}>
                    {!inactive && <img className="icon in-context-smaller no-margin avatar color-positive filter" src={"done.svg"}
                         alt={"img"}/> }
                    <h4 className="card-body highlight">{include}</h4>
                </div>
            ))}
            {t.subscriptionBasic.campaignText ?
                <div className="column">
                    <h3 className="card-header center-page">{t.employees}</h3>
                    {!inactive ?
                        <RangeSelector
                            min={0}
                            max={5}
                            startValue={startEmployeeCount}
                            onChange={employeeCountChange}
                        /> :
                        <h3 className="card-header center-page">{employeeCount}</h3>
                    }
                    <h3 className="card-body red-line">
                        {t.subscriptionBasic.price} {t.subscriptionBasic.currency}
                    </h3>
                    <h2 className="card-body">
                        {finalPrice} {t.subscriptionBasic.currency} (+{employeeCount * pricePerEmployee})
                    </h2>
                    <h3 className="card-body center-page highlight">{t.subscriptionBasic.campaignText}</h3>
                    <p className="card-body note">{t.subscriptionBasic.campaignNote}</p>
                </div> :
                <div className="column">
                <h3 className="card-header center-page">{t.employees}</h3>
                    {!inactive ?
                        <RangeSelector
                            min={0}
                            max={5}
                            startValue={startEmployeeCount}
                            onChange={employeeCountChange}
                        /> :
                        <h3 className="card-header center-page">{employeeCount}</h3>
                    }
                    <h2 className="headline center-page margin">
                        {finalPrice} {t.subscriptionBasic.currency} (+{employeeCount * pricePerEmployee})
                    </h2>
                    <p className="note">{t.informationTexts.priceExcludeVat}</p>
                </div>
            }

        </div>
    )
}

export function ExtendedSubscription({isSelected, onClick}: SubscriptionProps) {
    const t = requireTranslation();

    return (
        <div
            className={isSelected ? "card color-positive" : "card color-sec-background"}
            onClick={onClick}>
            <h2 className="card-header">{t.subscriptionExtended.name}</h2>
            <p className="card-body">{t.subscriptionExtended.included}</p>
            <div className="row">
                <h3 className="card-body red-line">{t.subscriptionExtended.price} {t.subscriptionExtended.currency}</h3>
                <h2 className="card-body highlight">{t.subscriptionExtended.campaignPrice} {t.subscriptionExtended.currency}</h2>
            </div>
            <h4 className="card-body center-page highlight">{t.subscriptionExtended.campaignText}</h4>
            <p className="card-body note">{t.subscriptionExtended.campaignNote}</p>
        </div>
    )
}

interface SelectSubscriptionProps {
    selectedSubscription: SubscriptionEnum,
    setSelectedSubscription: React.Dispatch<React.SetStateAction<SubscriptionEnum>>,
    handleChange: (selectedSubscription: SubscriptionEnum) => void
}

export function SelectSubscription(
    {selectedSubscription, setSelectedSubscription, handleChange}: SelectSubscriptionProps
) {
    const t = requireTranslation();

    return (
        <div className="column">
            <label
                className="input-label stay-top">
                {t.subscription}
            </label>
            <div className="content-inner-list">
                <BasicSubscription
                    isSelected={selectedSubscription === SubscriptionEnum.Basic}
                    onClick={() => {
                        setSelectedSubscription(SubscriptionEnum.Basic)
                        handleChange(SubscriptionEnum.Basic)
                    }} />
                <ExtendedSubscription
                    isSelected={selectedSubscription === SubscriptionEnum.Extended}
                    onClick={() => {
                        setSelectedSubscription(SubscriptionEnum.Extended)
                        handleChange(SubscriptionEnum.Extended)
                    }}/>
            </div>
        </div>
    )
}

export function useSelectSubscription<T>(
    setModel: new (data: any) => T,
    setModelInto: React.Dispatch<React.SetStateAction<T>>,
    nameInModel: string = "subscription"
): [
    SubscriptionEnum,
    React.Dispatch<React.SetStateAction<SubscriptionEnum>>,
    (selectedSubscription: SubscriptionEnum) => void
] {
    const [ selectedSubscription, setSelectedSubscription ] = useState(SubscriptionEnum.Basic);

    const handleChange = (selectedSubscription: SubscriptionEnum) => {
        setModelInto((prevState) => {
            return new setModel({ ...prevState, ...{[nameInModel]: selectedSubscription}})
        })
    }

    return [
        selectedSubscription,
        setSelectedSubscription,
        handleChange
    ]
}