import {useFormSetup, usePhoneNumberInput, useStandardHandleChange} from "../../utils/customHooks";
import {ExtendedRegistrationModel, RegisterUserModel} from "../../models/user/user";
import {useState} from "react";
import {Content, ContentForm} from "../common/ContentStandards";
import {
    PasswordWithConfirmPassword, PhoneInput,
    TextInputWithLabel,
    usePasswordWithConfirmPassword
} from "../common/FormCommons";
import {
    isInputErrorsEmpty,
    validateEmail,
    validateName,
    validatePhoneNumber,
    validateRequired,
    validateUsername
} from "../../utils/inputValidation";
import {InformationRow} from "../common/TextCommons";
import {SubscriptionEnum} from "../utils/Enums";
import {ConfirmationDialog, useDialog} from "../common/ConfirmationDialog";
import {OverlaySpinLoader} from "../common/SpinLoader";
import ErrorMessage from "../common/ErrorMessage";
import useAdminApi from "../../API/useAdminApi";

interface inputErrors {
    name: string;
    username: string;
    firmName: string;
    firmEmail: string;
    privateEmail: string;
    phone: string;
}

const initialInputErrors: inputErrors = {
    name: "",
    username: "",
    firmName: "",
    firmEmail: "",
    privateEmail: "",
    phone: ""
};

interface AdminAddUserProps {
    onSuccessAdd: () => void;
}

export default function AdminAddUser({onSuccessAdd}: AdminAddUserProps) {
    const [
        loading, setLoading,
        inputError, setInputError,
        error, setError,
        adminPassword, setAdminPassword
    ] = useFormSetup<string, inputErrors>("", initialInputErrors);
    const [ userRegistration, setUserRegistration ] = useState(new RegisterUserModel({
        name: "",
        username: "",
        password: "",
        firmName: "",
        firmEmail: "",
        privateEmail: "",
        phone: "",
        subscription: SubscriptionEnum.Free,
        employees: 0
    }));
    const [ handleChange ] = useStandardHandleChange(RegisterUserModel, setUserRegistration, setError);
    const {
        password,
        confirmPassword,
        isPasswordConfirmed,
        passwordConfirmIcon,
        handleChangePassword,
        inputErrors: passwordInputErrors,
        validatePasswordInput
    } = usePasswordWithConfirmPassword();
    const {
        handlePhoneNumberChange,
        inputFieldValue,
        setCountryCode
    } = usePhoneNumberInput(RegisterUserModel, setUserRegistration);
    const {
        isOpen,
        message: dialogMessage,
        setDialogMessage,
        toggle: toggleDialog
    } = useDialog();
    const { postUser } = useAdminApi();

    const validateInput = (): boolean => {
        const err = initialInputErrors;

        err.name = validateName(userRegistration.name);
        err.username = validateUsername(userRegistration.username);
        err.firmName = validateRequired(userRegistration.firmName);
        err.firmEmail = validateEmail(userRegistration.firmEmail);
        err.privateEmail = validateEmail(userRegistration.privateEmail);
        err.phone = validatePhoneNumber(userRegistration.phone);

        // console.log(userRegistration.subscription);

        setInputError(err);

        return isInputErrorsEmpty(err) && validatePasswordInput();
    };

    const handleInputDialog = () => {
        if (validateInput()) {
            setUserRegistration((prevState) => {
                return { ...prevState, ...{"password": password}}
            })
            setDialogMessage("Verify your admin status! Enter your unique pass:");
            toggleDialog();
        }
    };

    const handleAddUser = () => {
        if (validateRequired(adminPassword) === "") {
            setLoading(true);
            postUser(new ExtendedRegistrationModel(adminPassword, userRegistration))
                .then(() => onSuccessAdd())
                .catch((e) => {
                    if (e instanceof Error) {
                        setError(e.message);
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    return (
        <Content
            invisible={true}
            flexGrow={true}
            headline={"Add User"}
            centerHeadline={true}>
            <>
                <OverlaySpinLoader loading={loading} loadMessage={""} />

                {isOpen &&
                    <ConfirmationDialog
                        toggle={toggleDialog}
                        message={dialogMessage}
                        onConfirm={handleAddUser}>
                        <TextInputWithLabel
                            labelText={"Pass"}
                            initialValue={adminPassword}
                            onChange={(e) => setAdminPassword(e.target.value)} />
                    </ConfirmationDialog>
                }
            </>
            <ContentForm>
                <TextInputWithLabel
                    labelText={"Name"}
                    name="name"
                    initialValue={userRegistration.name}
                    error={inputError.name}
                    onChange={handleChange}/>
                <TextInputWithLabel
                    labelText={"Username"}
                    name="username"
                    initialValue={userRegistration.username}
                    error={inputError.username}
                    onChange={handleChange}/>
                <PasswordWithConfirmPassword
                    password={password}
                    confirmPassword={confirmPassword}
                    isPasswordConfirmed={isPasswordConfirmed}
                    passwordConfirmIcon={passwordConfirmIcon}
                    inputErrors={passwordInputErrors}
                    handleChangePassword={handleChangePassword} />
                <TextInputWithLabel
                    labelText={"Firm Name"}
                    name="firmName"
                    initialValue={userRegistration.firmName}
                    error={inputError.firmName}
                    onChange={handleChange}/>
                <TextInputWithLabel
                    labelText={"Firm Email"}
                    name="firmEmail"
                    initialValue={userRegistration.firmEmail}
                    error={inputError.firmEmail}
                    onChange={handleChange}/>
                <TextInputWithLabel
                    labelText={"Private Email"}
                    name="privateEmail"
                    initialValue={userRegistration.privateEmail}
                    error={inputError.privateEmail}
                    onChange={handleChange}/>
                <PhoneInput
                    initialValue={inputFieldValue}
                    setCountryCode={setCountryCode}
                    onChange={handlePhoneNumberChange} />
                <InformationRow title={"Subscription"} text={"Free"} />
                <div className="row reverse margin">
                    <button
                        className="filled"
                        onClick={handleInputDialog}>
                        Add
                    </button>
                </div>
                <ErrorMessage error={error} />
            </ContentForm>
        </Content>
    )
};